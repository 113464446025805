import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="We've Simplified the Payment Process">
    <section>

      <p>
        Quantic builds tools and software to make payments between two parties simple. Quantic builds software that connects Software used by Businesses to
        payment systems, credit card processors, banks, digital wallets, crypto-currency, offshore payment systems, offline servers Credit, debit, EMV/chip
        cards, gift cards, mobile payments, HSA and FSA cards.
        Quantic will custom build for your company secure, fast and flexible tools for POS or internet commerce. Quantic will help you create bill bay,
        invoicing, inventory, gift, loyalty, purchase order and other services that work in sync with your workflow. Our goal is to make collecting payments
        easy for you and your customers using automation, AI and BI
        We have created:
      </p>
      <ul>
        <li>subscription services</li>
        <li>on-demand marketplace</li>
        <li>e-commerce store</li>
        <li>point of sale</li>
        <li>crowdfunding platforms</li>
        <li>bill pay</li>
        <li>invoicing</li>
        <li>accounting systems</li>
      </ul>

      <p>
        We design meticulously APIs and incorporate functionality that makes your products easy to use for your users. We help your to scale up fast without
        compromising on security. We are on forefront of security; we monitor risks and threats like hawks and come up with solutions using our focus on the
        platform, people and culture.
        Our Development experience includes the following and not limited to:
      </p>
      <ul>
        <li>Point of Sale software</li>
        <li>eCommerce Carts</li>
        <li>Omni channel</li>
        <li>Payment gateways</li>
        <li>Credit card processing</li>
        <li>Offshore payments</li>
        <li>Lending</li>
        <li>ERP systems – purchase order, inventory, quotation, invoicing, warehousing</li>
        <li>Payments</li>
        <li>Billing</li>
        <li>ACH</li>
        <li>Tokenization</li>
        <li>Api development and integration</li>
        <li>EMV hardware to payment gateway to processors</li>
        <li>Hemp and cannibas payments</li>
        <li>Cbd and hemp oil payments</li>
        <li>Analytics – predictive and dashboards</li>
        <li>Accounting systems</li>
        <li>Hospitality systems</li>
      </ul>

      More solutions for your business. More support when you need it. More strength to stand on.
      <br/>
      <br/>
      Accept payments any time, anywhere
      <br/>
      Visa • MasterCard • Discover • American Express • JCB • PayPal • Visa Checkout • Apple Pay • E-check
      <br/>
      <br/>
      Quantic enables you to process payments in any way that works for your business, whether it’s through your website, mobile app or manual transactions. We
      make it easy to accept credit cards and help ensure you get paid. It’s that simple.
    </section>
  </Layout>
);

export default Page;
